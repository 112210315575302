/**
 *  Footer
 */
import React from "react";
import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Modal, ModalHeader, ModalBody, TabContent } from "reactstrap";
import ReactTooltip from 'react-tooltip';
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPolicy: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.Checkscroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.Checkscroll);
  }

  Checkscroll() {
    var scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;

    if (scrollTop > 350) {
      if (document.getElementById("back-to-top") != null) {
        document
          .getElementById("back-to-top")
          .setAttribute("style", "display:block");
      }
    } else {
      if (document.getElementById("back-to-top") != null) {
        document
          .getElementById("back-to-top")
          .setAttribute("style", "display:none");
      }
    }
  }
  ClicktoTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  toggleModalPolicy = () => {
    this.setState({ modalPolicy: !this.state.modalPolicy });
  };

  render() {
    let backtotop = { display: "none" };
    return (
      <div>
        <footer className="site-footer">
          <div className="footer-wrapper">
            <div className="site-info">
              <div className="footer-widget">
                <Container>
                  <div>
                    <Col  className="col-12 text-center">
                      <p>
                        {" "}
                        © Copyright 2022 <Link to="#">SVELTA</Link>
                      </p>
                    </Col>
                  </div>
                  <div className="clearfix" />
                </Container>
              </div>
            </div>
          </div>
        </footer>
        <ReactTooltip id='tooltip-whatsapp'place="left" type="dark" effect="solid">
          Olá, Como posso te ajudar?
        </ReactTooltip>
        <div className="box_whatsapp">
         <a href="https://wa.me/5531983402531"  data-tip data-for='tooltip-whatsapp' title="Whatsapp" target="_blank">
                 <i className="fa fa-whatsapp icon_whatsapp"/>
          </a>
        </div>
      </div>

    );
  }
}
export default Footer;
