import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDisclosure } from '../hooks/useDisclosure';
import images from '../images';
import styles from './searchSidebar.module.scss';
import IconButton from './iconButton';
import Sidebar from './sidebarSearch';

export default function SearchSidebar() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const history = useHistory();
  const [findText, setFindText] = useState('');
  const dateTime = new Date().getMilliseconds();

  useEffect(() => {
    document.addEventListener('togglesearch', () => {
      onToggle();
    });
  }, [onToggle]);

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      direction="right"
      contentClassName={styles.searchSideContainer}
    >
      <aside>
        <div className={styles.rowSearch}>
          <IconButton onClick={onClose} type="button">
            <img src={images.closeBlack} alt="close icon" />
          </IconButton>

          <input
            type="text"
            name={findText}
            className="input-search"
            placeholder="Busca"
            value={findText}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                history.push({
                  pathname: '/shop',
                  findText,
                  dateTime,
                });
                onClose();
              }
            }}
            onChange={(e) => setFindText(e.target.value)}
          />

          <IconButton
            onClick={() => {
              history.push({
                pathname: '/shop',
                findText,
                dateTime,
              });
              onClose();
            }}
            className={styles.action}
            type="button"
            aria-label="Procurar"
          >
            <img src={images.search} alt="Procurar" />
          </IconButton>
        </div>
      </aside>
    </Sidebar>
  );
}
