const images = {
  svelta: require('./svelta.svg').default,
  door: require('./door-icon.svg').default,
  heart: require('./heart-icon.svg').default,
  bag: require('./bag-icon.svg').default,
  menu: require('./menu-icon.svg').default,
  close: require('./close-icon.svg').default,
  search: require('./search-icon.svg').default,
  closeBlack: require('./close-icon-black.svg').default,
  userCircle: require('./user-circle.svg').default,
};

export default images;
