import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from '../src/store/store';
import { Provider } from 'react-redux';
import { CartProvider } from './context/cart';
import { AuthProvider } from './context/auth';
import { QueryClient, QueryClientProvider } from 'react-query';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-N4WX6KV',
  dataLayerName: 'PageDetails',
  events: {
    addToCart: 'addToCart',
    checkout: 'checkout'
  }
}

const ReactPixel =  require('react-facebook-pixel');
ReactPixel.default.init('3137500976337445');

const queryClient = new QueryClient();


ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <CartProvider>
          <App />
        </CartProvider>
      </AuthProvider>
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);

TagManager.initialize(tagManagerArgs);