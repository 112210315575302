import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.css';
import DefaultLayoutRouter from './layouts/defaultLayout';
import './Vendor.js';
import { lazy } from 'react';

//error react-dom.production.min.js:216 ChunkLoadError: Loading chunk 4 failed.
const lazyWithRetry = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(
        'page-has-been-force-refreshed'
      ) || 'false'
    );

    try {
      const component = await componentImport();

      window.localStorage.setItem(
        'page-has-been-force-refreshed',
        'false'
      );

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem(
          'page-has-been-force-refreshed',
          'true'
        );
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export default function App() {
  return (
    <Suspense fallback={<></>}>
      <Router>
        <ToastContainer />
        <Switch>
          <DefaultLayoutRouter
            exact
            path="/"
            component={lazyWithRetry(() => import('./component/shop'))}
          />
          <DefaultLayoutRouter
            path="/CheckOut"
            component={lazyWithRetry(() => import('./component/shop/CheckOut'))}
          />
          <DefaultLayoutRouter
            exact
            path="/shop"
            component={lazyWithRetry(() => import('./component/shop'))}
          />
          <DefaultLayoutRouter
            exact
            path="/shop/jumpsuit"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="jumpsuit"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/vestidos"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="dress"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/calcas"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="pants"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/blusas"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="shirt"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/tops"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="top"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/bermudas"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="bermuda"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/blazers"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="blazer"
          />
          <DefaultLayoutRouter
            exact
            path="/shop/kids"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="kid"
          />
           <DefaultLayoutRouter
            exact
            path="/shop/sale"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="sale"
          />
          <DefaultLayoutRouter
            path="/wishlist"
            component={lazyWithRetry(() => import('./component/WishList/WishList')
            )}
          />
          <DefaultLayoutRouter
            path="/ShopingCart"
            component={lazyWithRetry(() => import('./component/shop/ShopingCart'))}
          />

          <DefaultLayoutRouter
            path="/:category/:urlName/:code"
            component={lazyWithRetry(() =>
              import('./component/shop/ProductDetail')
            )}
          />

          <DefaultLayoutRouter
            path="/:category/:urlName/:code/:colorName"
            component={lazyWithRetry(() =>
              import('./component/shop/ProductDetail')
            )}
          />  

          <DefaultLayoutRouter
            path="/SuccessScreen"
            component={lazyWithRetry(() =>
              import('./component/Account/SuccessScreen')
            )}
          />

          <DefaultLayoutRouter
            path="/Login/:redirectTo?"
            component={lazyWithRetry(() => import('./component/Account/Login'))}
          />

          <DefaultLayoutRouter
            authOnly={true}
            path="/AccountProfile"
            component={lazyWithRetry(() =>
              import('./component/Account/AccountProfile')
            )}
            exact
          />
          <DefaultLayoutRouter
            authOnly={true}
            path="/OrderHistory"
            component={lazyWithRetry(() =>
              import('./component/Account/OrderHistory')
            )}
            exact
          />
          <DefaultLayoutRouter
            authOnly={true}
            path="/ChangePassword"
            component={lazyWithRetry(() =>
              import('./component/Account/ChangePassword')
            )}
            exact
          />
          <DefaultLayoutRouter
            path="/moda/consumo-consciente/CalendarioModa"
            component={lazyWithRetry(() =>
              import('./component/Blog/BlogSinglePage')
            )}
          />
          <DefaultLayoutRouter
            path="/conexao"
            component={lazyWithRetry(() => import('./component/Blog/Conexao'))}
          />

          {/* <DefaultLayoutRouter
            path="/elasusam"
            component={lazyWithRetry(() => import('./component/Blog/ElasUsam'))}
          />   */}

          <DefaultLayoutRouter
            exact
            path="/shop/elasusam"
            component={lazyWithRetry(() => import('./component/shop'))}
            key="elasusam"
          />          
        </Switch>
      </Router>
    </Suspense>
  );
}
