import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../auth';

const authContext = createContext(undefined);

export const AuthProvider = (props) => {
  const { children } = props;
  const [isReady, setIsReady] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUser(authUser);
      }
      setIsReady(true);
    });
  }, []);

  const value = { user, isReady };

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
};

export const useAuth = () => {
  const state = useContext(authContext);

  if (!state) throw new Error('useAuth must be used inside AuthProvider');

  return state;
};
