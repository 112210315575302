import firebase from 'firebase/app'

let firebaseConfig;

if (
  process.env.NODE_ENV === "development" ||
  process.env.REACT_APP_STAGE === "homologation"
) {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY_DEV,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN_DEV,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL_DEV,
    projectId: process.env.REACT_APP_FB_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID_DEV,
    appId: process.env.REACT_APP_FB_APP_ID_DEV,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID_DEV,
  };
}

if (process.env.REACT_APP_STAGE === "production") {
  firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_API_KEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FB_DATABASE_URL,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
  };
}

const firebaseApp = firebase.initializeApp(firebaseConfig);

export default firebaseApp;
