import products from '../reducers/products';
import purposes from '../reducers/purposes';
import checkout from '../reducers/checkout';
import login from '../reducers/login';
import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware , combineReducers, compose } from 'redux';

const rootReducer = combineReducers({
    productsReducer: products,
    purposesReducer: purposes,
    checkoutReducer: checkout,
    loginReducer: login
  });



export const store = createStore(rootReducer, compose(applyMiddleware(thunkMiddleware), window.devToolsExtension ? window.devToolsExtension() : f => f));