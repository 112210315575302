import { Redirect, Route } from 'react-router-dom';
import Header from '../components/header';
import BagSidebar from '../components/bagSidebar';
import SearchSidebar from '../components/searchSidebar';
import { useAuth } from '../context/auth';
// eslint-disable-next-line
import Footer from './footer/Footer';

const DefaultLayout = ({ authOnly, children }) => {
  const { user, isReady } = useAuth();

  if (authOnly && isReady && !user) return <Redirect to="/Login" />;

  return (
    <div style={{ paddingTop: 72 }}>
      <Header />
      <BagSidebar />
      <SearchSidebar />
      {children}
      <Footer />
    </div>
  );
};

const DefaultLayoutRouter = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <DefaultLayout>
          <Component {...props} />
        </DefaultLayout>
      )}
    />
  );
};

export default DefaultLayoutRouter;
