import { UPDATE_ACCOUNT_ADDRESS, UPDATE_BILLING_ADDRESS, UPDATE_STEP_CHECKOUT } from '../actions/actionTypes';

const accountAddress = {
  name: "",
  type: "account",
  zipCode: "",
  street: "",
  streetNumber: "",
  complementary: "",
  neighborhood: "",
  city: "",
  state: "",
  ibgeStateId: "",
  _id: "",
  user: "",
};
const billingAddress = {
  name: "",
  type: "billing",
  zipCode: "",
  street: "",
  streetNumber: "",
  complementary: "",
  neighborhood: "",
  city: "",
  state: "",
  ibgeStateId: "",
  _id: "",
  user: "",
};

export function checkout(state = { accountAddress: accountAddress, billingAddress: billingAddress, stepCheckout: "BILLING" }, action) {
  switch (action.type) {

    case UPDATE_ACCOUNT_ADDRESS:
      return {
        ...state,
        accountAddress: action.accountAddress,
      }

    case UPDATE_BILLING_ADDRESS:
      return {
        ...state,
        billingAddress: action.billingAddress,
      }

    case UPDATE_STEP_CHECKOUT:
      return {
        ...state,
        stepCheckout: action.stepCheckout,
      }


    default:
      return state;
  }
}

export default checkout;
