import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';

const cartContext = createContext(undefined);

export const CartProvider = (props) => {
  const { children } = props;
  const [cart, setCart] = useState([]);

  const addToCart = useCallback(
    (product) => {
      const isItemAlreadyInCart = cart.some((p) => isSameProduct(p, product));
      if (isItemAlreadyInCart) return;

      setCart([...cart, product]);
    },
    [cart]
  );

  const isSameProduct = (productOld, productNew) => {
    return (
      productOld.ProductID === productNew.ProductID &&
      productOld.ProductSize === productNew.ProductSize
    );
  };

  const removeFromCart = useCallback(
    (product) => {
      setCart(cart.filter((p) => !isSameProduct(p, product)));
    },
    [cart]
  );

  const eraseCart = useCallback(() => {
    setCart([]);
  }, []);

  const replaceCart = useCallback((products = []) => {
    setCart(products);
  }, []);

  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('LocalCartItems')) || [];
    setCart(savedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem('LocalCartItems', JSON.stringify(cart));
  }, [cart]);

  const value = { cart, addToCart, removeFromCart, eraseCart, replaceCart };

  return <cartContext.Provider value={value}>{children}</cartContext.Provider>;
};

export const useCart = () => {
  const state = useContext(cartContext);

  if (!state) throw new Error('useCart must be used inside CartProvider');

  return state;
};
