
export const LIST_ALL_PRODUCTS = 'LIST_ALL_PRODUCTS';
export const LOADING_ALL_PRODUCTS = 'LOADING_ALL_PRODUCTS';
export const ERROR_LOADING_ALL_PRODUCTS = 'ERROR_LOADING_ALL_PRODUCTS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const LIST_PRODUCTS_CART = 'LIST_PRODUCTS_CART';
export const UPDATE_PRODUCTS_CART = 'UPDATE_PRODUCTS_CART';
export const UPDATE_PRODUCTS_WISH = 'UPDATE_PRODUCTS_WISH';
export const LIST_PRODUCTS_WISH = 'LIST_PRODUCTS_WISH';
export const UPDATE_ACCOUNT_ADDRESS = 'UPDATE_ACCOUNT_ADDRESS';
export const UPDATE_BILLING_ADDRESS= 'UPDATE_BILLING_ADDRESS';
export const UPDATE_STEP_CHECKOUT = 'UPDATE_STEP_CHECKOUT';
export const UPDATE_LOGIN = 'UPDATE_LOGIN';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_AUTH = 'UPDATE_USER_AUTH';
export const LIST_ALL_PURPOSES= 'LIST_ALL_PURPOSES';
export const LOADING_ALL_PURPOSES = 'LOADING_ALL_PURPOSES';
export const ERROR_LOADING_ALL_PURPOSES = 'ERROR_LOADING_ALL_PURPOSES';