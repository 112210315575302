import { LIST_ALL_PURPOSES, LOADING_ALL_PURPOSES, ERROR_LOADING_ALL_PURPOSES } from '../actions/actionTypes';



export function purposes(state = { purposes: [] }, action) {
  switch (action.type) {
    case LOADING_ALL_PURPOSES: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case LIST_ALL_PURPOSES:
      return {
        ...state,
        purposes: action.purposes,
        loading: false,
        error: ''
      }

    case ERROR_LOADING_ALL_PURPOSES: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    default:
      return state;
  }
}

export default purposes;
