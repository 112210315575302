import firebaseApp from "../config/firebase";
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';



export const auth = firebaseApp.auth();


export async function createUser(newUser) {
  let autentication;
  try {
    autentication = await firebaseApp
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password);
    return autentication.user;
  } catch (error) {
    let msgError = "Erro ao cadastar usuário. Por favor tente novamente mais tarde.";
    if (error.code === "auth/email-already-in-use") {
      msgError = "O e-mail informado já foi cadastrado.";
    }
    if (error.code === "auth/invalid-email") {
      msgError = "O e-mail informado é inválido.";
    }
    return msgError;
  }
}

export async function loginEmail(user) {
  let autentication;
  try {
    autentication = await firebaseApp.auth().signInWithEmailAndPassword(user.email, user.password);
    return autentication.user;
  } catch (error) {
    let msgError = "Erro ao logar no sistema. Por favor tente novamente mais tarde.";
    if (error.code === "auth/wrong-password") {
      msgError = "A senha está errada ou o seu usuário não foi cadastrado.";
    }
    return msgError;
  }
}

export async function changePassword(newPassword) {
  let autentication;
  try {
    autentication = await firebaseApp.auth().currentUser;

    let response = await autentication
      .updatePassword(newPassword)
      .then(function () {
        // Update successful.
        return "sucesso";
      })
      .catch(function (error) {
        // An error happened.
        let msgError = "";
        if (error.code === "auth/weak-password") {
          msgError = "A senha precisa de 6 caracteres no mínimo.";
        } else if (error.code === 'auth/requires-recent-login') {
          msgError = "deslogar";
        }
        return msgError;
      });
    return response;
  } catch (error) {
    let msgError = "Erro ao trocar de senha. Por favor tente novamente mais tarde.";
    return msgError;
  }
}

export async function resetPassword(email) {
  let emailSend;
  try {
    emailSend = await firebaseApp.auth().sendPasswordResetEmail(email);
    return emailSend;
  } catch (error) {
    let msgError =
      "Erro ao enviar e-mail para recuperar senha. Por favor tente novamente mais tarde.";
    if (error.code === "auth/invalid-email") {
      msgError = "O e-mail informado é inválido.";
    }
    if (error.code === "auth/user-not-found") {
      msgError = "Não foi possível encontrar usuário cadastrado com esse e-mail.";
    }
    return msgError;
  }
}



export async function updatePasswordWithCode(code, password) {

  try {
    let response = await firebaseApp.auth().confirmPasswordReset(code, password).then(function () {
      return "success";
    }).catch(function (error) {
      let msgError = "";

      if (error.code === "auth/expired-action-code" || error.code === "auth/invalid-action-code") {
        msgError = "O código enviado para o e-mail já foi expirado.";
      }
      else if (error.code === "auth/weak-password") {
        msgError = "A senha precisa de 6 caracteres no mínimo.";
      }
      else if (error.code === "auth/user-not-found") {
        msgError = "Não foi possível encontrar usuário cadastrado com esse e-mail.";
      }
      else {
        msgError = "Erro ao atualizar senha. Tente novaente.";
      }
      return msgError;
    });
    return response;
  } catch (error) {
    let msgError = "Erro ao atualizar . Por favor tente novamente mais tarde.";
    return msgError;
  }
}

export async function verifyPasswordResetCode(code) {
  try {
    let response = await firebaseApp.auth().verifyPasswordResetCode(code).then(function () {
      return "success";
    }).catch(function (error) {
      return "error";
    });
    return response;
  } catch (error) {
    let msgError = "error";
    return msgError;
  }
}


export async function updateEmail(email) {
  try {
    var user = await firebaseApp.auth().currentUser;
    let response = await user.updateEmail(email).then(function() {

      return "success";
    }).catch(function (error) {
      let msg = "Não foi possível atualizar o e-mail";
      if(error.code === "auth/requires-recent-login"){
        msg = "Será necessário deslogar e logar para reativar a sua sessão.";
      } else if(error.code === "auth/email-already-in-use"){
        msg = "O e-mail já foi cadastrado. É necessário utilizar outro e-mail.";
      }
      return msg;
    });
    return response;
  } catch (error) {
    let msgError = "error";
    return msgError;
  }
}
