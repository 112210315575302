import { UPDATE_USER, UPDATE_LOGIN, UPDATE_USER_AUTH } from '../actions/actionTypes';

const user = {

};

const userAuth = {

};

const isLogin = false;

export function login(state = { user: user, userAuth: userAuth, isLogin: isLogin }, action) {
  switch (action.type) {

    case UPDATE_USER:
      return {
        ...state,
        user: action.user,
      }

      case UPDATE_USER_AUTH:
        return {
          ...state,
          userAuth: action.userAuth,
        }

    case UPDATE_LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
      }

    default:
      return state;
  }
}

export default login;
