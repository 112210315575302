import { LIST_ALL_PRODUCTS, LOADING_ALL_PRODUCTS, ERROR_LOADING_ALL_PRODUCTS, FILTER_PRODUCTS, LIST_PRODUCTS_CART, UPDATE_PRODUCTS_CART , UPDATE_PRODUCTS_WISH, LIST_PRODUCTS_WISH } from '../actions/actionTypes';

const producstCartStorage = localStorage.getItem("LocalCartItems") ? JSON.parse(localStorage.getItem("LocalCartItems")) : [];
const productsWishStorage = localStorage.getItem("LocalWishListItems") ? JSON.parse(localStorage.getItem("LocalWishListItems")) : [];

export function products(state = { products: [], productsCart: producstCartStorage, productsWish: productsWishStorage }, action) {
  switch (action.type) {
    case LOADING_ALL_PRODUCTS: {
      return {
        ...state,
        loading: true,
        error: ''
      };
    }
    case LIST_ALL_PRODUCTS:
      return {
        ...state,
        products: action.products,
        originalProducts: action.products,
        loading: false,
        error: ''
      }

    case LIST_PRODUCTS_CART:
      return {
        ...state,
        productsCart: action.productsCart
      }


    case FILTER_PRODUCTS:
      return {
        ...state,
        products: action.products
      }


    case UPDATE_PRODUCTS_CART:
      return {
        ...state,
        productsCart: action.productsCart
      }

    case LIST_PRODUCTS_WISH:
      return {
        ...state,
        productsWish: action.productsWish
      }

    case UPDATE_PRODUCTS_WISH:
      return {
        ...state,
        productsWish: action.productsWish
      }

    case ERROR_LOADING_ALL_PRODUCTS: {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }

    default:
      return state;
  }
}

export default products;
