import { useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import { useCart } from '../context/cart';
import { useDisclosure } from '../hooks/useDisclosure';
import images from '../images';
import styles from './bagSidebar.module.scss';
import BlockButton from './button';
import IconButton from './iconButton';
import Sidebar from './sidebar';

export default function BagSidebar() {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { cart, removeFromCart } = useCart();
  const history = useHistory();
  const total = useMemo(() => getTotalPrice(cart), [cart]);

  useEffect(() => {
    document.addEventListener('togglebag', () => {
      onToggle();
    });
  }, [onToggle]);

  return (
    <Sidebar
      isOpen={isOpen}
      onClose={onClose}
      direction="right"
      contentClassName={styles.bagContainer}
    >
      <aside>
        <header>
          <IconButton onClick={onClose} type="button">
            <img src={images.closeBlack} alt="close icon" />
          </IconButton>
          <h1>Sacola ({cart.length.toString()})</h1>
        </header>
        <div className={styles.products}>
          {cart?.map((product) => {
            return (
              <article key={product.ProductID}>
                <IconButton
                  onClick={() => removeFromCart(product)}
                  type="button"
                >
                  <img src={images.closeBlack} alt="remove from cart icon" />
                </IconButton>
                <img
                  className={styles.productImage}
                  src={product.ProductImage}
                  alt={product.ProductName}
                />
                <header>
                  <h1>{product.ProductName}</h1>
                </header>
                <div className={styles.productInfo}>
                  <p>
                    <span>Cor:</span> {product.ProductColor?.colorName}
                  </p>
                  <p>
                    <span>Tamanho:</span> {product.ProductSize}
                  </p>
                  <p>
                    <span>Quantidade:</span> {product.ProductQuantity}
                  </p>
                  <p>
                    <span>Preço</span>{' '}
                    <NumberFormat
                      value={product.ProductPrice}
                      displayType="text"
                      thousandSeparator="."
                      prefix="R$"
                      decimalScale="2"
                      fixedDecimalScale
                      decimalSeparator=","
                    />
                  </p>
                </div>
              </article>
            );
          })}
        </div>
        <footer>
          <p>
            <span>Subtotal</span>
            <NumberFormat
              value={total}
              displayType="text"
              thousandSeparator="."
              prefix="R$"
              decimalScale="2"
              fixedDecimalScale
              decimalSeparator=","
            />
          </p>
          <BlockButton
            onClick={() => {
              history.push('/ShopingCart');
              onClose();
            }}
            disabled={!cart.length}
          >
            Ir para o pagamento
          </BlockButton>
        </footer>
      </aside>
    </Sidebar>
  );
}

function getTotalPrice(cart = []) {
  return (
    cart?.reduce(
      (total, product) =>
        total +
        product.ProductQuantity *
          (product.ProductSale
            ? product.ProductSalePrice
            : product.ProductPrice),
      0
    ) ?? 0
  );
}
